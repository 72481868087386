.pagination_7b7c030298 {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
}

.input_7b7c030298 {
  max-width: 48px;
  margin: 0 2px;
}

.previous_7b7c030298,
.next_7b7c030298 {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  background: none;
  border: none;
  padding: 5px;
}
.previous_7b7c030298 svg,
.next_7b7c030298 svg {
  fill: var(--glz-color-neutral-tone-4);
}
.previous_7b7c030298:hover svg, .previous_7b7c030298:focus-visible svg,
.next_7b7c030298:hover svg,
.next_7b7c030298:focus-visible svg {
  fill: var(--glz-color-neutral-tone-4);
}
.previous_7b7c030298.hide_7b7c030298,
.next_7b7c030298.hide_7b7c030298 {
  display: none;
}
.previous_7b7c030298:focus-visible,
.next_7b7c030298:focus-visible {
  border-radius: 0.1px;
  outline: 1px solid var(--glz-color-primary);
  outline-offset: 2px;
  padding-top: 5px;
}

.previous_7b7c030298 {
  margin-right: 2px;
}

.next_7b7c030298 {
  margin-left: 2px;
}

.item_7b7c030298 {
  display: inline;
  padding: 4px 8px;
  cursor: pointer;
  color: var(--glz-color-primary);
  user-select: none;
  background: none;
  border: none;
  font-size: 14px;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  margin: 0 1px;
}
.item_7b7c030298:hover, .item_7b7c030298:focus-visible {
  color: var(--glz-color-primary-tone-1);
}
.item_7b7c030298.active_7b7c030298 {
  color: var(--glz-color-neutral-tone-5);
  border-radius: 2px;
  background-color: var(--glz-color-neutral-tone-2);
}
.item_7b7c030298:focus-visible {
  outline: 1px solid var(--glz-color-primary);
  outline-offset: 2px;
}
.item_7b7c030298:focus-visible {
  border-radius: 0.1px;
  outline: 1px solid var(--glz-color-primary);
  outline-offset: 2px;
  padding-top: 5px;
}

.ellipsis_7b7c030298 {
  color: var(--glz-color-neutral-tone-4);
  font-size: 14px;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  line-height: 20px;
  padding: 0 4px;
  margin: 0 2px;
}

.total_7b7c030298 {
  margin: 0 2px;
  user-select: none;
  color: var(--glz-color-neutral-tone-4);
}
.total_7b7c030298 .total-index_7b7c030298 {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--glz-color-neutral-tone-4);
  font-size: 14px;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  padding: 4px;
}
.total_7b7c030298 .total-index_7b7c030298:hover, .total_7b7c030298 .total-index_7b7c030298:focus-visible {
  color: var(--glz-color-primary-tone-1);
}
.total_7b7c030298 .total-index_7b7c030298:focus-visible {
  border-radius: 0.1px;
  outline: 1px solid var(--glz-color-primary);
  outline-offset: 2px;
  padding-top: 5px;
}

.tooltips_7b7c030298 {
  display: block;
}
@media (max-width: 879px) {
  .tooltips_7b7c030298 {
    display: none;
  }
}

.hidden_7b7c030298 {
  visibility: hidden;
}