.container_6cc43afe63 {
  display: block;
  position: relative;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
}

.label_6cc43afe63 {
  display: flex;
  align-items: center;
  padding: 0 8px;
  position: relative;
  border: 1px solid var(--glz-color-neutral-tone-3);
  background: var(--glz-color-neutral-tone-0);
}
.label_6cc43afe63.focused_6cc43afe63 {
  border: 1px solid var(--glz-color-neutral-tone-4);
  box-shadow: 0 1px 2px rgba(var(--glz-color-neutral-tone-5-rgb), 0.21);
}
.label_6cc43afe63.gradient_6cc43afe63 {
  position: absolute;
  right: 0;
  top: 1px;
  bottom: 1px;
  background-image: linear-gradient(90deg, rgba(var(--glz-color-neutral-tone-0-rgb), 0.0001), var(--glz-color-neutral-tone-0));
  width: 30px;
}
.label_6cc43afe63.disabled_6cc43afe63 {
  background-color: var(--glz-color-neutral);
  border: 1px solid var(--glz-color-neutral-tone-3);
  cursor: not-allowed;
}
.label_6cc43afe63.disabled_6cc43afe63 .input_6cc43afe63 {
  -webkit-text-fill-color: var(--glz-color-neutral-tone-3);
  color: var(--glz-color-neutral-tone-3);
  cursor: not-allowed;
}
.label_6cc43afe63.error_6cc43afe63 {
  background: var(--glz-color-error-tone-3);
  border-color: var(--glz-color-error);
}
.label_6cc43afe63.error_6cc43afe63.gradient_6cc43afe63 {
  background-image: linear-gradient(90deg, rgba(var(--glz-color-neutral-tone-0-rgb), 0.0001), var(--glz-color-error-tone-3));
}
.label_6cc43afe63 .gradient-pad_6cc43afe63 {
  right: 30px;
}

.input-wrapper_6cc43afe63 {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.input-wrapper_6cc43afe63 input {
  width: 100%;
  padding-left: 0;
}

.suggestionsContainer_6cc43afe63 {
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: -1px;
  background: var(--glz-color-neutral-tone-0);
  border: 1px solid var(--glz-color-neutral-tone-4);
  z-index: 99;
  overflow: hidden;
  box-sizing: border-box;
}

.suggestionsContainerOpen_6cc43afe63 {
  display: block;
  max-height: 200px;
  overflow: auto;
}

.before_6cc43afe63 {
  line-height: 10px;
  padding-right: 8px;
}

.tooltip_6cc43afe63 {
  display: flex;
}

.after-icon_6cc43afe63 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.clear-after-icon_6cc43afe63 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  border: 0;
  background-color: transparent;
  transition: fill 0.3s;
}
.clear-after-icon_6cc43afe63 svg {
  fill: var(--glz-color-neutral-tone-3);
}
.clear-after-icon_6cc43afe63:hover svg {
  fill: var(--glz-color-neutral-tone-5);
}

.input_6cc43afe63 {
  caret-color: var(--glz-color-neutral-tone-3);
  width: 100%;
  background: none;
  border: 0;
  padding: 5px 0 5px 0;
  font-size: 12px;
  font-weight: normal;
  color: var(--glz-color-neutral-tone-5);
  position: relative;
  height: 32px;
  outline: 0;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  box-sizing: border-box;
}
.input_6cc43afe63::placeholder {
  color: var(--glz-color-neutral-tone-3);
}
.input_6cc43afe63:focus {
  outline: 0;
}

.placeholder_6cc43afe63 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 12px;
  font-weight: normal;
  color: var(--glz-color-neutral-tone-3);
  white-space: nowrap;
  height: 30px;
  line-height: 15px;
  padding: 8px 0;
  box-sizing: border-box;
}

.suggestionsList_6cc43afe63 {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: 100%;
  position: relative;
}

.suggestion_6cc43afe63 {
  display: block;
  list-style: none;
  margin: 0;
  padding: 8px;
  font-size: 12px;
  line-height: 18px;
  color: var(--glz-color-neutral-tone-5);
  cursor: pointer;
}

.suggestionHighlighted_6cc43afe63 {
  background: var(--glz-color-primary-tone-3);
}

.match_6cc43afe63 {
  font-weight: bold;
  color: rgba(var(--glz-color-neutral-tone-5-rgb), 0.8);
  text-decoration: underline;
}