.tip_a9c9702e96.tip_a9c9702e96 {
  box-shadow: 0px 8px 24px rgba(var(--glz-color-neutral-tone-5-rgb), 0.16);
}

.wrapper_a9c9702e96 {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 20px;
}

.header_a9c9702e96 {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: normal;
  color: var(--glz-color-neutral-tone-5);
}
.header_a9c9702e96.small_a9c9702e96 {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: var(--glz-color-neutral-tone-5);
}
.header_a9c9702e96.icon_a9c9702e96 {
  display: flex;
}

.content_a9c9702e96.text-content_a9c9702e96 {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: var(--glz-color-neutral-tone-5);
}
.content_a9c9702e96.text-header_a9c9702e96 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: normal;
  color: var(--glz-color-neutral-tone-5);
}

.footer_a9c9702e96 {
  display: flex;
  align-items: flex-start;
}
.footer_a9c9702e96 .action_a9c9702e96:not(:last-child) {
  margin-right: 10px;
}

.action_a9c9702e96 {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.medium_a9c9702e96.with-width_a9c9702e96 {
  width: 388px;
}
.medium_a9c9702e96 .header_a9c9702e96 {
  padding: 24px 56px 12px 24px;
}
.medium_a9c9702e96 .content_a9c9702e96 {
  padding: 0 24px;
}
.medium_a9c9702e96 .footer_a9c9702e96 {
  padding: 12px 24px 24px 24px;
}

.small_a9c9702e96.with-width_a9c9702e96 {
  width: 235px;
}
.small_a9c9702e96 .header_a9c9702e96 {
  padding: 16px 46px 12px 16px;
}
.small_a9c9702e96 .content_a9c9702e96 {
  padding: 0 16px;
}
.small_a9c9702e96 .footer_a9c9702e96 {
  padding: 12px 16px 16px 16px;
}

.header_a9c9702e96.without-content_a9c9702e96 {
  padding-bottom: 0;
}

.theme-dark_a9c9702e96 {
  background-color: rgba(var(--glz-color-neutral-tone-5-rgb), 0.9);
}

.theme-yellow_a9c9702e96 {
  background-color: var(--glz-color-warning-tone-3);
}

.theme-green_a9c9702e96 {
  background-color: var(--glz-color-success-tone-3);
}

.theme-light_a9c9702e96 {
  background-color: var(--glz-color-neutral-tone-0);
}

.close-button_a9c9702e96 {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 0;
  padding: 0;
  background: none;
  color: transparent;
  outline: 0;
  fill: var(--glz-color-neutral-tone-3);
}
.close-button_a9c9702e96.small_a9c9702e96 {
  right: 16px;
  top: 16px;
}
.close-button_a9c9702e96:hover {
  fill: var(--glz-color-neutral-tone-4);
}
.close-button_a9c9702e96:focus {
  outline: 0;
}
.close-button_a9c9702e96:focus-visible {
  fill: var(--glz-color-neutral-tone-5);
}
.close-button_a9c9702e96:active {
  fill: var(--glz-color-neutral-tone-5);
}

.is-center-alignment_a9c9702e96 {
  justify-content: center;
}
.is-center-alignment_a9c9702e96 .mobile-footer_a9c9702e96 {
  margin-top: 16px;
}