.outer-wrapper_aa2877ea0c {
  position: fixed;
  bottom: 0;
  background-color: rgba(var(--glz-color-neutral-tone-5-rgb), 0.8);
  overflow: auto;
  overscroll-behavior-y: contain;
  filter: saturate(1);
  top: 0 !important;
  right: 0;
  left: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.outer-wrapper_aa2877ea0c.overlay-invisible_aa2877ea0c {
  background-color: transparent;
}
.outer-wrapper_aa2877ea0c::-webkit-scrollbar {
  display: none;
}

.content-layout_aa2877ea0c {
  position: absolute;
  display: flex;
  min-height: calc(100% + 1px);
  align-items: center;
  justify-content: center;
  top: 0 !important;
  right: 0;
  left: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.content-layout_aa2877ea0c::-webkit-scrollbar {
  display: none;
}

.popup_aa2877ea0c {
  position: relative;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 8px 24px 0px rgba(var(--glz-color-neutral-tone-5-rgb), 0.16);
  background: var(--glz-color-neutral-tone-0);
  margin: 32px auto 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.popup_aa2877ea0c.size-medium_aa2877ea0c {
  max-width: 640px;
}
.popup_aa2877ea0c.size-small_aa2877ea0c {
  max-width: 448px;
}

.popup-header_aa2877ea0c {
  padding: 32px 72px 16px 32px;
}

.popup-header-title_aa2877ea0c {
  color: var(--glz-color-neutral-tone-5);
  line-height: 32px;
  letter-spacing: -0.3px;
  font-size: 26px;
  font-weight: normal;
  margin: 0;
}

.popup-header-subtitle_aa2877ea0c {
  color: var(--glz-color-neutral-tone-5);
  line-height: 20px;
  letter-spacing: -0.3px;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  padding: 12px 0 0 0;
}

.back-link-block_aa2877ea0c {
  width: 100%;
  padding-left: 3px;
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 16px;
  padding: 0 4px 0 0;
}

.back-link_aa2877ea0c svg {
  fill: var(--glz-color-neutral-tone-4);
}
.back-link_aa2877ea0c:hover svg {
  fill: var(--glz-color-primary-tone-1);
}
.back-link_aa2877ea0c:active svg {
  fill: var(--glz-color-primary-tone-2);
}

.close-button_aa2877ea0c {
  position: absolute;
  top: 32px;
  right: 32px;
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
}
.close-button_aa2877ea0c svg {
  fill: var(--glz-color-neutral-tone-3);
}
.close-button_aa2877ea0c:hover svg {
  fill: var(--glz-color-neutral-tone-4);
}

.popup-content_aa2877ea0c {
  padding: 0 32px 16px;
}
.popup-content_aa2877ea0c.theme-transparent_aa2877ea0c {
  background-color: transparent;
}
.popup-content_aa2877ea0c.theme-white_aa2877ea0c {
  background-color: var(--glz-color-neutral-tone-0);
}
.popup-content_aa2877ea0c.theme-feature_aa2877ea0c {
  background-color: var(--glz-color-warning-tone-4);
}
.popup-content_aa2877ea0c.theme-light_aa2877ea0c {
  background-color: var(--glz-color-warning-tone-3);
}
.popup-content_aa2877ea0c.theme-gray_aa2877ea0c {
  background-color: var(--glz-color-neutral);
}
.popup-content_aa2877ea0c.theme-error_aa2877ea0c {
  background-color: var(--glz-color-error-tone-3);
  color: var(--glz-color-error);
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 16px;
}

.popup-footer_aa2877ea0c {
  background-color: var(--glz-color-neutral-tone-0);
  padding: 16px 32px 40px 32px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;
}

.outer-wrapper_aa2877ea0c:not(.is-mobile_aa2877ea0c) .popup-footer_aa2877ea0c.bg-gray_aa2877ea0c {
  background-color: var(--glz-color-neutral);
  padding-top: 32px;
}
.outer-wrapper_aa2877ea0c:not(.is-mobile_aa2877ea0c) .popup-footer_aa2877ea0c.footer-top-margin_aa2877ea0c {
  margin-top: 16px;
}
.outer-wrapper_aa2877ea0c:not(.is-mobile_aa2877ea0c) .popup-content_aa2877ea0c.theme-error_aa2877ea0c + .popup-footer_aa2877ea0c.footer-top-margin_aa2877ea0c {
  margin-top: 0;
}

.popup-action-wrapper-button_aa2877ea0c {
  display: inline-flex;
  align-self: center;
}
.popup-action-wrapper-button_aa2877ea0c:not(:last-child) {
  margin-right: 16px;
}
.popup-action-wrapper-button_aa2877ea0c.action-second_aa2877ea0c {
  margin-left: auto;
}

.is-mobile_aa2877ea0c .popup_aa2877ea0c {
  display: flex;
  flex-direction: column;
  margin: auto auto 0 auto;
  border-radius: 0;
  min-height: 100vh;
  min-height: 100dvh;
  outline: 1px solid var(--glz-color-neutral-tone-0);
}
.is-mobile_aa2877ea0c .popup_aa2877ea0c.auto-height-mobile_aa2877ea0c {
  min-height: auto;
}
.is-mobile_aa2877ea0c .popup-header_aa2877ea0c {
  padding: 24px 56px 16px 16px;
}
.is-mobile_aa2877ea0c .close-button_aa2877ea0c {
  top: 24px;
  right: 16px;
}
.is-mobile_aa2877ea0c .popup-content_aa2877ea0c {
  padding: 0 16px 16px;
}
.is-mobile_aa2877ea0c .popup-content_aa2877ea0c.theme-error_aa2877ea0c {
  margin-top: auto;
}
.is-mobile_aa2877ea0c .popup-footer_aa2877ea0c {
  border-radius: 0;
  justify-content: center;
  flex-direction: column;
  margin: auto 0 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 44px;
}
.is-mobile_aa2877ea0c .popup-action-wrapper-button_aa2877ea0c {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 0 16px 0;
}
.is-mobile_aa2877ea0c .popup-action-wrapper-button_aa2877ea0c:last-child {
  margin-bottom: 0;
}
.is-mobile_aa2877ea0c .popup-action-wrapper-button_aa2877ea0c.action-second_aa2877ea0c {
  margin-left: 0;
}
.is-mobile_aa2877ea0c .popup-action-wrapper-button_aa2877ea0c.action-cancel_aa2877ea0c {
  order: 10;
  margin-top: 8px;
  margin-bottom: 0;
}
.is-mobile_aa2877ea0c .popup-action-wrapper-button_aa2877ea0c.action-cancel_aa2877ea0c + .action-second_aa2877ea0c {
  margin-bottom: 16px;
}